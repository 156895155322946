import { db } from "../firebase/firebase-config";
import { types } from "../types/types";
import axios from "axios";
import Swal from "sweetalert2";

export const agendarTurno = async (
  año,
  mes,
  dia,
  horarioSeleccionado,
  deal_id,
  navigate,
  email,
  patente,
  dispatch,
  type,
  sucursal,
  paracaidas
) => {
  const data = {
    year: String(año),
    month: String(mes),
    day: String(dia),
    time: horarioSeleccionado,
    dealId: deal_id,
    type: type,
    agent: email,
    patente: patente,
    sucursal: sucursal,
    parachuter: paracaidas,
  };

  const dbRef = await db.collection("deals").doc(`${deal_id}`).get();

  const { car } = dbRef.data();

  if (Object.keys(car).length === 0) {
    Swal.fire({
      title: "No tenés un auto para que cotizemos.",
      icon: "info",
      text: "Por favor, clickea en el botón para cargar tu auto.",
      confirmButtonColor: "#29a1dc",
      confirmButtonText: `<a href=https://deal.chevroletsanjorge.com.ar/cargar/${deal_id} style="text-decoration:none; color:white; font-size:15px">Cargar</a>`,
    });
  } else {
    let response = axios
      .post("https://infoauto-348420.rj.r.appspot.com/appointment", data)
      .then((resp) => {
        dispatch(startLoading(false, resp.status));
        if (type === "pretoma") {
          navigate(`/resumen-san-jorge/take/${deal_id}`);
        } else {
          navigate(`/resumen-san-jorge/${deal_id}`);
        }
        Swal.fire("Agendado", "Turno agendado correctamente.", "success");
      })
      .catch((e) => {
        console.log(e);
        dispatch(startLoading(false, e.request.status));
      });
  }
};

export const cancelarTurnoDb = async (
  deal_id,
  email,
  navigate,
  dispatch,
  setLoadingResponse
) => {
  const data = {
    year: "0000",
    month: "",
    day: "0",
    time: "",
    dealId: deal_id,
    type: "inspeccion",
    agent: email,
  };

  let response = axios
    .post("https://infoauto-348420.rj.r.appspot.com/appointment", data)
    .then((dataDb) => {
      setLoadingResponse(false);
      Swal.fire("Cancelado", "Turno cancelado correctamente.", "success");
      dispatch(sendPutTurnos("cancelar"));
      navigate(`/turnos-san-jorge/${deal_id}`);
    })
    .catch((e) => {
      console.log(e);
    });
};

export const modificarTurnoDb = async (
  deal_id,
  email,
  navigate,
  dispatch,
  setLoadingResponse
) => {
  const data = {
    year: "0000",
    month: "",
    day: "0",
    time: "",
    dealId: deal_id,
    type: "inspeccion",
    agent: email,
  };

  let response = axios
    .post("https://infoauto-348420.rj.r.appspot.com/appointment", data)
    .then((dataDb) => {
      setLoadingResponse(false);
      Swal.fire("Realizado", "Ya podés editar tu turno", "success");
      dispatch(sendPutTurnos("modificar"));
      navigate(`/turnos-san-jorge/${deal_id}`);
    })
    .catch((e) => {
      console.log(e);
    });
};

export const dataResumen = async (dispatch, deal_id) => {
  axios
    .get(
      `https://infoauto-348420.rj.r.appspot.com/get/appointment/check/${deal_id}`
    )
    .then((response) => {
      // Obtenemos los datos
      const { car, appointment, current_appointment, patente } = response.data;

      if (current_appointment) {
        dispatch(
          startDataResumen(
            car.brand,
            car.color,
            car.km,
            car.model,
            car.version,
            car.year,
            appointment.fecha,
            appointment.horario,
            patente
          )
        );
      } else {
        dispatch(
          startDataResumenVehiculo(
            car.brand,
            car.color,
            car.km,
            car.model,
            car.version,
            car.year,
            patente
          )
        );
      }
    })
    .catch((e) => {
      // Capturamos los errores
      console.log(e);
    });
};

const startDataResumenVehiculo = (
  brand,
  color,
  km,
  model,
  version,
  year,
  patente
) => ({
  type: types.DataResumenVehiculo,
  payload: {
    brand,
    color,
    km,
    model,
    version,
    year,
    patente,
  },
});

const startDataResumen = (
  brand,
  color,
  km,
  model,
  version,
  year,
  fecha,
  horario,
  patente
) => ({
  type: types.DataResumen,
  payload: {
    brand,
    color,
    km,
    model,
    version,
    year,
    fecha,
    horario,
    patente,
  },
});

export const dataResumenDate = async (dispatch, fecha, horario, patente) => {
  dispatch(startDataResumenDate(fecha, horario, patente));
};

export const startDataResumenDate = (fecha, horario, patente) => ({
  type: types.DataResumenDate,
  payload: {
    fecha,
    horario,
    patente,
  },
});

export const sendPutTurnos = (data) => ({
  type: types.PutTurnos,
  payload: {
    data,
  },
});

export const startLoading = (loading, status) => ({
  type: types.Loading,
  payload: {
    loading,
    status,
  },
});
